<template>
  <div
    v-if="loading === false && (item == null || item.length === 0)"
    :class="this.emptyName !== 'none' ? 'text-center' : ''"
  >
    <img :src="path()" alt="" />
    <p>{{ title }} bulunamadı.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundInfo",
  props: ["item", "title", "emptyName"],
  methods: {
    path() {
      if (this.emptyName) {
        if (this.emptyName === "none") return "";
        return require("../../assets/images/empty_" + this.emptyName + ".svg");
      } else return require("../../assets/images/empty_" + "offer" + ".svg");
    },
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
  },
};
</script>
